<template>
  <v-container
    class="pa-0"
    style="background-color: white; height: 100%"
  >
    <v-card
      flat
      height="100%"
    >
      <div
        style="height: 100%;"
      >
        <v-toolbar
          flat
          width="100%"
          class="toolbar"
        >
          <v-btn
            icon
            rounded
            @click.stop="$emit('close')"
          >
            <v-icon>
              mdi-arrow-left
            </v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ $t('actions|select_template') }}
          </v-toolbar-title>
        </v-toolbar>

        <div
          class="pl-4"
        >
          <div>
            <v-row
              no-gutters
            >
              <v-col
                v-for="(section, index) in availableSections"
                :key="index"
                cols="12"
              >
                <v-checkbox
                  v-model="checkboxSelection"
                  multiple
                  :value="section"
                  class="pa-0"
                >
                  <template v-slot:label>
                    <div>
                      {{ section }}
                    </div>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-card-text class="pa-0 mt-2">
          <v-list
            v-if="templates.length"
            v-show="checkboxSelection.includes($t('templates|my_templates'))"
            flat
          >
            <v-subheader>{{ $t('templates|my_templates').toUpperCase() }}</v-subheader>
            <v-list-item-group
              v-if="templates.length"
              color="primary"
            >
              <v-list-item
                v-for="(template, i) in templates"
                :key="i"
                :value="template"
                class="rounded-lg lightBackground my-4 mx-2"
                @click="select(template._id)"
              >
                <v-list-item-icon class="pa-0 mr-1">
                  <v-icon
                    color="primary"
                  >
                    {{ tempIcon }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content class="mt-1">
                  <v-list-item-title>{{ template.name }}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ $t('common|description') }}: {{ template.description ? template.description : 'none' }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ $t('common|category') }}: {{ template.category }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ $t('common|created_by') }}:
                    <div
                      v-if="template.onCreatedBy === 'Account'"
                    >
                      <v-chip
                        small
                        outlined
                        color="primary"
                        class="ma-2"
                      >
                        <v-icon
                          small
                          color="primary"
                          class="mr-1 ml-n1"
                        >
                          mdi-account
                        </v-icon>
                        {{ template.createdBy.accountData.accountName ? template.createdBy.accountData.accountName : account.accountName }}
                      </v-chip>
                    </div>

                    <div v-if="template.onCreatedBy === 'Company'">
                      <v-chip
                        small
                        outlined
                        color="primary"
                        class="ma-2"
                      >
                        <v-icon
                          small
                          color="primary"
                          class="mr-1 ml-n1"
                        >
                          mdi-domain
                        </v-icon>
                        {{ template.createdBy.companyData.companyName }}
                      </v-chip>
                    </div>

                    <div v-if="template.onCreatedBy === 'Group'">
                      <v-chip
                        small
                        outlined
                        color="primary"
                        class="ma-2"
                      >
                        <v-icon
                          small
                          color="primary"
                          class="mr-1 ml-n1"
                        >
                          mdi-account-group
                        </v-icon>
                        {{ template.createdBy.groupName }}
                      </v-chip>
                    </div>
                  </v-list-item-subtitle>

                  <v-list-item-subtitle>
                    {{ $t('common|shared_with') }}:
                    <v-row no-gutters>
                      <v-col
                        v-for="acc in template.sharedWith.accounts"
                        :key="acc._id"
                        cols="12"
                      >
                        <v-chip
                          small
                          outlined
                          color="primary"
                          class="ma-2"
                        >
                          <v-icon
                            small
                            color="primary"
                            class="mr-1 ml-n1"
                          >
                            mdi-account
                          </v-icon>
                          {{ acc.accountData.accountName ? acc.accountData.accountName : account.accountName }}
                        </v-chip>
                      </v-col>
                      <v-col
                        v-for="c in template.sharedWith.company"
                        :key="c._id"
                        cols="12"
                      >
                        <v-chip
                          small
                          outlined
                          color="primary"
                          class="ma-2"
                        >
                          <v-icon
                            small
                            color="primary"
                            class="mr-1 ml-n1"
                          >
                            mdi-domain
                          </v-icon>
                          {{ c.companyData.companyName }}
                        </v-chip>
                      </v-col>
                      <v-col
                        v-for="(g, index) in template.sharedWith.groups"
                        :key="index"
                        cols="12"
                      >
                        <v-chip
                          small
                          outlined
                          color="primary"
                          class="ma-2"
                        >
                          <v-icon
                            small
                            color="primary"
                            class="mr-1 ml-n1"
                          >
                            mdi-account-group
                          </v-icon>
                          {{ g.groupName }}
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <span v-else>{{ $t('expressions|no_available_templates') }}</span>
          <v-divider
            v-if="companyTemplates && companyTemplates.length"
            v-show="checkboxSelection.includes(company.companyData.companyName) && checkboxSelection.includes($t('templates|my_templates'))"
          />
          <v-list
            v-if="companyTemplates && companyTemplates.length"
            v-show="checkboxSelection.includes(company.companyData.companyName)"
            flat
          >
            <v-subheader>{{ company.companyData.companyName.toUpperCase() + ' ' + $t('templates|templates').toUpperCase() }}</v-subheader>
            <v-list-item-group
              v-if="companyTemplates && companyTemplates.length"
              color="primary"
            >
              <v-list-item
                v-for="(template, i) in companyTemplates"
                :key="i"
                :value="template"
                class="rounded-lg lightBackground my-4 mx-2"
                @click="select(template._id)"
              >
                <v-list-item-icon class="pa-0 mr-1">
                  <v-icon
                    color="primary"
                  >
                    {{ tempIcon }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content class="mt-1">
                  <v-list-item-title>
                    {{ template.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ $t('common|description') }}: {{ template.description ? template.description : '' }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ $t('common|category') }}: {{ template.category }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ $t('common|created_by') }}:
                    <div
                      v-if="template.onCreatedBy === 'Account'"
                    >
                      <v-chip
                        small
                        outlined
                        color="primary"
                        class="ma-2"
                      >
                        <v-icon
                          small
                          color="primary"
                          class="mr-1 ml-n1"
                        >
                          mdi-account
                        </v-icon>
                        {{ template.createdBy.accountData.accountName ? template.createdBy.accountData.accountName : account.accountName }}
                      </v-chip>
                    </div>

                    <div v-if="template.onCreatedBy === 'Company'">
                      <v-chip
                        small
                        outlined
                        color="primary"
                        class="ma-2"
                      >
                        <v-icon
                          small
                          color="primary"
                          class="mr-1 ml-n1"
                        >
                          mdi-domain
                        </v-icon>
                        {{ template.createdBy.companyData.companyName }}
                      </v-chip>
                    </div>

                    <div v-if="template.onCreatedBy === 'Group'">
                      <v-chip
                        small
                        outlined
                        color="primary"
                        class="ma-2"
                      >
                        <v-icon
                          small
                          color="primary"
                          class="mr-1 ml-n1"
                        >
                          mdi-account-group
                        </v-icon>
                        {{ template.createdBy.groupName }}
                      </v-chip>
                    </div>
                  </v-list-item-subtitle>

                  <v-list-item-subtitle>
                    {{ $t('common|shared_with') }}:
                    <v-row no-gutters>
                      <v-col
                        v-for="acc in template.sharedWith.accounts"
                        :key="acc._id"
                        cols="12"
                      >
                        <v-chip
                          small
                          outlined
                          color="primary"
                          class="ma-2"
                        >
                          <v-icon
                            small
                            color="primary"
                            class="mr-1 ml-n1"
                          >
                            mdi-account
                          </v-icon>
                          {{ acc.accountData.accountName ? acc.accountData.accountName : account.accountName }}
                        </v-chip>
                      </v-col>
                      <v-col
                        v-for="c in template.sharedWith.company"
                        :key="c._id"
                        cols="12"
                      >
                        <v-chip
                          small
                          outlined
                          color="primary"
                          class="ma-2"
                        >
                          <v-icon
                            small
                            color="primary"
                            class="mr-1 ml-n1"
                          >
                            mdi-domain
                          </v-icon>
                          {{ c.companyData.companyName }}
                        </v-chip>
                      </v-col>
                      <v-col
                        v-for="(g, index) in template.sharedWith.groups"
                        :key="index"
                        cols="12"
                      >
                        <v-chip
                          small
                          outlined
                          color="primary"
                          class="ma-2"
                        >
                          <v-icon
                            small
                            color="primary"
                            class="mr-1 ml-n1"
                          >
                            mdi-account-group
                          </v-icon>
                          {{ g.groupName }}
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <span v-else>{{ $t('expressions|no_available_templates') }}</span>
          </v-list>
          <v-divider
            v-if="filteredGroupTemplates && filteredGroupTemplates.length"
            v-show="checkboxSelection.includes($t('templates|available_group_templates')) && checkboxSelection.length > 1 "
          />
          <v-list
            v-if="filteredGroupTemplates && filteredGroupTemplates.length"
            v-show="checkboxSelection.includes(this.$t('templates|available_group_templates'))"
            three-line
            subheader
            flat
          >
            <v-list-item-group
              v-if="filteredGroupTemplates && filteredGroupTemplates.length"
              color="primary"
            >
              <v-subheader>{{ $t('templates|available_group_templates').toUpperCase() }}</v-subheader>
              <v-list-item
                v-for="(template, i) in filteredGroupTemplates"
                :key="i"
                :value="template"
                three-line
                class="rounded-lg lightBackground my-4 mx-2"
                @click="select(template._id)"
              >
                <v-list-item-icon
                  class="pa-0 mr-1"
                >
                  <v-icon
                    color="primary"
                  >
                    {{ tempIcon }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content class="mt-1">
                  <v-list-item-title>{{ template.name }}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ $t('common|description') }}: {{ template.description ? template.description : '' }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ $t('common|category') }}: {{ template.category }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ $t('common|created_by') }}:
                    <div
                      v-if="template.onCreatedBy === 'Account'"
                    >
                      <v-chip
                        small
                        outlined
                        color="primary"
                        class="ma-2"
                      >
                        <v-icon
                          small
                          color="primary"
                          class="mr-1 ml-n1"
                        >
                          mdi-account
                        </v-icon>
                        {{ template.createdBy.accountData.accountName ? template.createdBy.accountData.accountName : account.accountName }}
                      </v-chip>
                    </div>

                    <div v-if="template.onCreatedBy === 'Company'">
                      <v-chip
                        small
                        outlined
                        color="primary"
                        class="ma-2"
                      >
                        <v-icon
                          small
                          color="primary"
                          class="mr-1 ml-n1"
                        >
                          mdi-domain
                        </v-icon>
                        {{ template.createdBy.companyData.companyName }}
                      </v-chip>
                    </div>

                    <div v-if="template.onCreatedBy === 'Group'">
                      <v-chip
                        small
                        outlined
                        color="primary"
                        class="ma-2"
                      >
                        <v-icon
                          small
                          color="primary"
                          class="mr-1 ml-n1"
                        >
                          mdi-account-group
                        </v-icon>
                        {{ template.createdBy.groupName }}
                      </v-chip>
                    </div>
                  </v-list-item-subtitle>

                  <v-list-item-subtitle>
                    {{ $t('common|shared_with') }}:
                    <v-row no-gutters>
                      <v-col
                        v-for="acc in template.sharedWith.accounts"
                        :key="acc._id"
                        cols="12"
                      >
                        <v-chip
                          small
                          outlined
                          color="primary"
                          class="ma-2"
                        >
                          <v-icon
                            small
                            color="primary"
                            class="mr-1 ml-n1"
                          >
                            mdi-account
                          </v-icon>
                          {{ acc.accountData.accountName ? acc.accountData.accountName : account.accountName }}
                        </v-chip>
                      </v-col>

                      <v-col
                        v-for="c in template.sharedWith.company"
                        :key="c._id"
                        cols="12"
                      >
                        <v-chip
                          small
                          outlined
                          color="primary"
                          class="ma-2"
                        >
                          <v-icon
                            small
                            color="primary"
                            class="mr-1 ml-n1"
                          >
                            mdi-account
                          </v-icon>
                          {{ c.companyData.companyName }}
                        </v-chip>
                      </v-col>
                      <v-col
                        v-for="(g, index) in template.sharedWith.groups"
                        :key="index"
                        cols="12"
                      >
                        <v-chip
                          small
                          outlined
                          color="primary"
                          class="ma-2"
                        >
                          <v-icon
                            small
                            color="primary"
                            class="mr-1 ml-n1"
                          >
                            mdi-account-group
                          </v-icon>
                          {{ g.groupName }}
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <span v-else>{{ $t('expressions|no_available_templates') }}</span>
          </v-list>
        </v-card-text>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { uniqBy } from '@/utils/utils'
import { mdiTextBoxOutline } from '@mdi/js'

export default {
  mixins: [],
  data () {
    return {
      tempIcon: mdiTextBoxOutline,
      checkboxSelection: []
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company,
      templates: state => state.templates.templates,
      companyTemplates: state => state.companyTemplates.companyTemplates,
      groupTemplates: state => state.groupTemplates.groupTemplates
    }),
    filteredGroupTemplates () {
      let groupTemps = []
      if (!this.account.companyId) return
      this.company.groups.forEach(group => {
        const index = group.groupMembers.findIndex(m => m._id === this.account._id)
        if (index !== -1) {
          this.groupTemplates.forEach(temp => {
            temp.sharedWith.groups.find(g => g._id === group._id)
            if (temp) {
              groupTemps.push(temp)
            }
          })
        }
      })
      const uniqueGroupTemps = uniqBy(groupTemps, '_id')
      return uniqueGroupTemps
    },
    availableSections () {
      let sections = []
      if (this.templates && this.templates.length) {
        sections.push(this.$t('templates|my_templates'))
      }
      if (this.companyTemplates && this.companyTemplates.length) {
        sections.push(this.company.companyData.companyName)
      }
      if (this.filteredGroupTemplates && this.filteredGroupTemplates.length) {
        sections.push(this.$t('templates|available_group_templates'))
      }
      return sections
    }
  },
  created () {
    window.scrollTo(0, 0)
    this.onOpen()
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast',
      addCaseWorkbenchTemplate: 'cases/addCaseWorkbenchTemplate'
    }),
    onOpen () {
      if (this.templates && this.templates.length) {
        this.checkboxSelection.push(this.$t('templates|my_templates'))
      }
      if (this.companyTemplates && this.companyTemplates.length) {
        this.checkboxSelection.push(this.company.companyData.companyName)
      }
      if (this.filteredGroupTemplates && this.filteredGroupTemplates.length) {
        this.checkboxSelection.push(this.$t('templates|available_group_templates'))
      }
    },
    async select (templateRef) {
      const res = await this.$dialog.prompt({
        title: this.$t('templates|template_name'),
        text: this.$t('actions|enter_template_name'),
        textField: {
          // Any addtional props/attrs that will be binded to v-text-field component
          type: 'text',
          outlined: true,
          dense: true,
          class: 'mt-6'
        },
        actions: [
          {
            text: this.$t('actions|submit'), color: 'primary'
          }
        ]
      })
      if (!res) return
      try {
        const payload = {
          templateRef,
          name: res
        }
        await this.addCaseWorkbenchTemplate({ _id: this.$route.params._id, workbenchId: this.$route.params.workbenchId, payload })
        this.addToast({
          title: this.$t('message|template_save_success'),
          color: 'white',
          snackbarColor: 'success'
        })
        this.$emit('close')
      } catch (e) {
        console.error(e, 'error')
        this.addToast({
          title: this.$t('error|something_went_wrong'),
          color: 'white',
          snackbarColor: 'error'
        })
        this.$emit('close')
      }
    }
  }
}
</script>

<style scoped>
  .toolbar {
    position: sticky;
    top: 48px;
    z-index: 1;
  }
</style>
